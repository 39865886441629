var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.hideSelection)?_c('div',{staticClass:"check-all-holder"},[_c('v-checkbox',{attrs:{"label":"alle auswählen","ripple":false},on:{"click":_vm.allCheckCLicked},model:{value:(_vm.selectedAll),callback:function ($$v) {_vm.selectedAll=$$v},expression:"selectedAll"}})],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"sort-by":['date'],"sort-desc":true,"show-select":_vm.showSelect,"singleSelect":_vm.singleSelect,"item-class":_vm.sealedRowClass,"item-key":"id","search":_vm.performSearch,"page":_vm.pageHistory,"footer-props":{
    'items-per-page-text':'Zeilen pro Seite'
}},on:{"update:page":[function($event){_vm.pageHistory=$event},_vm.pageChange]},scopedSlots:_vm._u([{key:"item.buttons",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"buttons-holder"},_vm._l((_vm.actions),function(action){return _c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isButtonDisabled(action, item)),expression:"!isButtonDisabled(action, item)"}],key:action.id,staticClass:"green--text custom-action-icon",class:_vm.isButtonDisabled(action, item) ? 'disabled' : '',attrs:{"src":action.icons},on:{"click":function($event){return _vm.$emit(action.action, item)}}})}),0)]}},{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedItems.length > 0),expression:"selectedItems.length > 0"}]},_vm._l((headers),function(header,i){return _c('td',{key:i},[(i == 0)?_c('div',[_vm._v(" Total: ")]):_vm._e(),(header.value == 'specialHours')?_c('div',{staticClass:"custom-green-text"},[_vm._v(" "+_vm._s(_vm.totalSpecialHours + " h")+" ")]):_vm._e(),(header.value == 'normalHours')?_c('div',{staticClass:"custom-green-text"},[_vm._v(" "+_vm._s(_vm.totalNormalHours + " h")+" ")]):_vm._e(),(header.value == 'material')?_c('div',{staticClass:"custom-green-text"},[_vm._v(" "+_vm._s(_vm.totalMaterial + " Fr.")+" ")]):_c('div')])}),0)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.date)?_c('div',[_c('v-icon',{staticClass:"custom-calendar-icon"},[_vm._v("mdi-calendar-blank-outline")]),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.date)))])],1):_vm._e()]}},{key:"item.treeName",fn:function(ref){
var item = ref.item;
return [(item.treeName)?_c('div',{staticClass:"d-flex"},[_c('img',{staticClass:"custom-map-area-icon",attrs:{"src":require("../../assets/images/icons/baumliste.svg")}}),_c('span',[_vm._v(_vm._s(item.treeName))])]):_vm._e()]}},{key:"item.finishDate",fn:function(ref){
var item = ref.item;
return [(item.finishDate)?_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("formatFullDate")(item.finishDate)))])]):_vm._e()]}},{key:"item.colorTypeId",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getColorClass(item.colorTypeId)})]}},{key:"item.sealDays",fn:function(ref){
var item = ref.item;
return [(!item.isSealed && item.sealDays > 0)?_c('div',[_c('span',[_vm._v(_vm._s(item.sealDays)+" Tage")])]):(item.isSealed && !item.finishDate)?_c('div',[_c('span',[_vm._v("Versiegelt, aber keine Daten gefunden")])]):(item.isSealed)?_c('div',[_c('span',[_vm._v("Versiegelt")])]):_vm._e()]}},{key:"item.examinationFinishedUsers",fn:function(ref){
var item = ref.item;
return [(
          item.examinationFinishedUsers
            ? item.examinationFinishedUsers.length > 0
            : false
        )?_c('div',{staticClass:"name-holder"},[_vm._l((item.examinationFinishedUsers),function(user){return _c('div',{staticClass:"acc-name-duration"},[_c('span',{staticClass:"acc-name",attrs:{"title":user.firstName + ' ' + user.lastName}},[_c('span',{staticClass:"initials"},[_vm._v(" "+_vm._s(_vm.getInitials(user.firstName, user.lastName))+" ")])])])}),(item.freelanceFirstName && item.freelanceLastName)?_c('div',{staticClass:"acc-name-duration"},[_c('span',{staticClass:"acc-name",attrs:{"title":item.freelanceFirstName + ' ' + item.freelanceLastName}},[_c('span',{staticClass:"initials"},[_vm._v(" "+_vm._s(_vm.getInitials(item.freelanceFirstName, item.freelanceLastName))+" ")])])]):_vm._e()],2):_vm._e()]}},{key:"header.data-table-select",fn:function(ref){return undefined}},{key:"item.data-table-select",fn:function(ref){
        var item = ref.item;
        var isSelected = ref.isSelected;
return [_c('div',{staticClass:"locked-baum-controle"},[(!_vm.hideSelection)?_c('v-checkbox',{attrs:{"color":"#1DB954","ripple":false,"value":item},on:{"click":function($event){return _vm.$emit('selectedRow', _vm.mySelectedItems)}},model:{value:(_vm.mySelectedItems),callback:function ($$v) {_vm.mySelectedItems=$$v},expression:"mySelectedItems"}}):_vm._e(),(item.isSealed === true)?_c('v-icon',{staticClass:"custom-lock-icon red",attrs:{"medium":""}},[_vm._v("mdi-lock-outline")]):_vm._e()],1)]}}]),model:{value:(_vm.mySelectedItems),callback:function ($$v) {_vm.mySelectedItems=$$v},expression:"mySelectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }